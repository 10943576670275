<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block"
      @click="closeModal"
    >
      <div class="modal-dialog" role="document" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Добавить группу</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="display: block" @click="closeModal">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="mb-2">Название</label>
              <input type="text" class="form-control" v-model="name" />
            </div>
            <div class="form-group">
              <label class="mb-2">Описание</label>
              <textarea class="form-control" rows="3" v-model="description"></textarea>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :class="{ disabled: !isValid }"
              @click="saveForm"
            >
              <span v-if="id">Сохранить</span>
              <span v-else>Добавить</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeModal"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import api from "@/core/api";
import { computed } from "@vue/runtime-core";
export default {
  props: ["values"],
  setup(props, context) {
    const id = ref(props.values.id);
    const name = ref(props.values.name);
    const description = ref(props.values.description);

    const isValid = computed(() => name.value.trim() !== "" && description.value.trim() !== "");

    function closeModal() {
      context.emit("closeModal");
    }

    async function saveForm() {
      try {
        if (id.value !== "") {
          await api.updateGroup(
            {
              name: name.value,
              description: description.value,
            },
            id.value
          );
        } else {
          await api.createGroup({
            name: name.value,
            description: description.value,
          });
        }
        context.emit("closeModal");
        context.emit("dataSaved");
      } catch (error) {
        alert(error.message);
      }
    }

    return {
      id,
      name,
      description,
      isValid,
      closeModal,
      saveForm,
    };
  },
};
</script>
