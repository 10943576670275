<template>
  <div>
    <div class="modal-backdrop fade show"></div>
    <div
      class="modal fade show"
      tabindex="-1"
      role="dialog"
      style="display: block"
      @click="closeModal"
    >
      <div class="modal-dialog modal-lg modal-dialog-scrollable" role="document" @click.stop>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Состав группы</h5>
            <div class="__search">
              <input
                type="text"
                class="form-control"
                placeholder="Фильтр пользователей..."
                v-model="search"
              />
              <i class="fa fa-search"></i>
            </div>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" style="display: block" @click="closeModal">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-sm">
              <tbody>
                <tr v-for="user in allUsersList" :key="user.id" v-show="rowIsVisible(user)">
                  <th>
                    <input type="checkbox" v-model="userCheckboxes" :value="user.id" />
                  </th>
                  <td>{{ user.login }}</td>
                  <td>{{ user.data.email }}</td>
                  <td>{{ user.data.name }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="saveForm">
              <span v-if="id">Сохранить</span>
              <span v-else>Добавить</span>
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeModal"
            >
              Отмена
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import api from "@/core/api";
export default {
  props: ["values"],
  setup(props, context) {
    const id = ref(props.values.id);
    const users = ref(props.values.users);
    const allUsersList = ref([]);
    const userCheckboxes = ref(props.values.users.map((el) => el.id));
    const search = ref("");

    function closeModal() {
      context.emit("closeModal");
    }

    function rowIsVisible(user) {
      if (search.value.trim() === "") return true;
      if (user.login?.toLowerCase().includes(search.value.toLowerCase())) return true;
      if (user.data.address?.toLowerCase().includes(search.value.toLowerCase())) return true;
      if (user.data.email?.toLowerCase().includes(search.value.toLowerCase())) return true;
      if (user.data.name?.toLowerCase().includes(search.value.toLowerCase())) return true;
      if (user.data.phone?.toLowerCase().includes(search.value.toLowerCase())) return true;
      return false;
    }

    fetchAllUsersList();
    function fetchAllUsersList() {
      api.getUsers().then((resp) => {
        allUsersList.value = resp.data;
      });
    }

    async function saveForm() {
      try {
        await api.updateGroup(
          {
            ...props.values,
            users: userCheckboxes.value.map((el) => {
              return { id: el };
            }),
          },
          id.value
        );
        context.emit("closeModal");
        context.emit("dataSaved");
      } catch (error) {
        alert(error.message);
      }
    }

    return {
      id,
      users,
      allUsersList,
      userCheckboxes,
      search,
      rowIsVisible,
      closeModal,
      saveForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.__search {
  width: 50%;
  position: relative;
  input {
    padding-right: 40px;
  }
  i {
    position: absolute;
    top: 12px;
    right: 12px;
    pointer-events: none;
  }
}
</style>
