<template>
  <div class="card card-custom card-stretch">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start justify-content-center flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Группы</h3>
      </div>
      <div class="card-toolbar">
        <button type="button" class="btn btn-success" @click.prevent="editGroup(defaultGroup)">
          Добавить группу
        </button>
        <CreateGroup
          v-if="showCreateGroup"
          @closeModal="showCreateGroup = false"
          @dataSaved="fetchGroups"
          :values="valuesToEdit"
        />
      </div>
    </div>
    <div class="card-body">
      <table class="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Название</th>
            <th>Описание</th>
            <th>Действия</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(group, groupInd) in groups" :key="group.id">
            <th>{{ groupInd + 1 }}</th>
            <td>{{ group.name }}</td>
            <td>{{ group.description }}</td>
            <td>
              <a
                class="btn btn-sm btn-clean btn-icon mr-2"
                title="Редактировать состав группы"
                @click.prevent="editUsers(group)"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <polygon points="0 0 24 0 24 24 0 24"></polygon>
                      <path
                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        opacity="0.3"
                      ></path>
                      <path
                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                    </g>
                  </svg>
                </span>
              </a>
              <a
                class="btn btn-sm btn-clean btn-icon mr-2"
                title="Редактировать группу"
                @click.prevent="editGroup(group)"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                        transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "
                      ></path>
                      <rect
                        fill="#000000"
                        opacity="0.3"
                        x="5"
                        y="20"
                        width="15"
                        height="2"
                        rx="1"
                      ></rect>
                    </g>
                  </svg>
                </span>
              </a>
              <a
                class="btn btn-sm btn-clean btn-icon"
                title="Удалить группу"
                @click.prevent="deleteGroup(group)"
              >
                <span class="svg-icon svg-icon-md">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect x="0" y="0" width="24" height="24"></rect>
                      <path
                        d="M6,8 L6,20.5 C6,21.3284271 6.67157288,22 7.5,22 L16.5,22 C17.3284271,22 18,21.3284271 18,20.5 L18,8 L6,8 Z"
                        fill="#000000"
                        fill-rule="nonzero"
                      ></path>
                      <path
                        d="M14,4.5 L14,4 C14,3.44771525 13.5522847,3 13,3 L11,3 C10.4477153,3 10,3.44771525 10,4 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z"
                        fill="#000000"
                        opacity="0.3"
                      ></path>
                    </g>
                  </svg>
                </span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <GroupUsers
        v-if="showGroupUsers"
        @closeModal="showGroupUsers = false"
        @dataSaved="fetchGroups"
        :values="valuesToEdit"
      />
    </div>
  </div>
</template>

<script>
import api from "@/core/api";
import { ref } from "@vue/reactivity";

import CreateGroup from "../components/CreateGroup.vue";
import GroupUsers from "../components/GroupUsers.vue";
import { auth } from "@/core/services/auth";
import { useRouter } from "vue-router";

export default {
  components: {
    CreateGroup,
    GroupUsers,
  },
  setup() {
    const router = useRouter();

    const showCreateGroup = ref(false);
    const showGroupUsers = ref(false);
    const groups = ref([]);
    const defaultGroup = ref({
      id: "",
      name: "",
      description: "",
    });
    const valuesToEdit = ref({ ...defaultGroup.value });

    fetchGroups();

    function fetchGroups() {
      api
        .getGroups()
        .then((resp) => {
          groups.value = resp.data;
        })
        .catch((err) => {
          if (auth.loggedIn()) {
            if (err.response.data.detail === "The user doesn't have enough privileges") {
              router.push({ name: "profile" });
            }
          } else {
            router.push({ name: "login" });
          }
        });
    }

    function editUsers(group) {
      valuesToEdit.value = { ...group };
      showGroupUsers.value = true;
    }

    function editGroup(group) {
      valuesToEdit.value = { ...group };
      showCreateGroup.value = true;
    }

    function deleteGroup(group) {
      if (confirm(`Удалить группу ${group.name}`)) {
        api
          .deleteGroup(group.id)
          .then(() => {
            fetchGroups();
          })
          .catch((error) => {
            alert(error.response.data.detail);
          });
      }
    }

    return {
      groups,
      defaultGroup,
      showCreateGroup,
      showGroupUsers,
      valuesToEdit,
      fetchGroups,
      editUsers,
      editGroup,
      deleteGroup,
    };
  },
};
</script>
